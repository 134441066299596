/* For portrait orientation */
@media screen and (orientation: portrait) {
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
  }

  .app {
    text-align: center;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
  }

  /* Adjust header to span the full width */
  header {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 5vh; /* Use vh for responsive padding */
    background-color: transparent;
    position: absolute;
  }

  /* Main content in portrait mode */
  .content {
    display: flex;
    color: #e1e1e1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15vh; /* Adjust to make room for header */
    flex-grow: 1;
  }

  .title {
    font-size: 6vw; /* Dynamically adjust title size */
    font-weight: bold;
  }

  .subtitle {
    font-size: 5vw; /* Adjust subtitle size */
  }

  button {
    padding: 3vh 10vw; /* Adjust button padding for mobile */
    font-size: 1.2rem;
    cursor: pointer;
  }

  .play-button {
    background-color: #4CAF50;
    color: white;
    padding: 3vh 12vw; /* Adjust for portrait mode */
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 2.5vh;
  }

  .terms-and-conditions {
    margin-bottom: 5vh;
    font-size: 4vw;
  }

  /* Modal Styling for portrait mode */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  color: black;
  padding: 4vh;
  border-radius: 2rem;
  max-width: 90vw;
  width: 80vw; /* Responsive modal width */
  max-height: 70vh; /* Make the modal smaller */
  overflow-y: auto; /* Enable scrolling when content overflows */
  text-align: left;
}

/* Adjust title size inside the modal */
.modal-content h3 {
  margin-top: 0;
  font-size: 6vw;
}

.modal-content ul {
  list-style-type: none;
  padding-left: 0;
}

.modal-content ul li {
  margin-bottom: 4vh;
  font-size: 4vw;
}

/* Scrollbar styling (optional) */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Close button styling */
.close-button {
  background-color: #f44336;
  color: white;
  padding: 2vh 8vw;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}

  /* Adjust footer for portrait mode */
  .footer {
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    padding: 1vh 0;
    background-color: #0d0d0c;
    border: solid white 0.01rem;
    color: #f1f1f1;
  }

  .footer a {
    color: #027a23;
    font-weight: bold;
    text-decoration: none;
  }

  .footer a:hover {
    text-decoration: underline;
  }
}
