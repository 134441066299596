.game-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    margin-top: 1rem;
    width: 90%;
    max-width: 60vw;
    padding: 20px;
    position: absolute;
  }
  
  .videos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  
  .video {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    background-color: black;
    margin-bottom: 10px;
  }
  
  .chess-and-video {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .chessboard {
    display: inline-block;
  }
  