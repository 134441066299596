/* Set the entire app background to black */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  color: white; /* Set the text color to white for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; 
  overflow: hidden;
}

.app {
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensure header and footer are spaced apart */
  min-height: 100vh;
  width: 100vw;
}

/* Position header at the top */
header {
  display: flex;
  width: 100%; /* Make the header span the full width */
  justify-content: center;
  padding: 1rem;
  background-color: transparent; /* Optional: make header transparent */
  position: absolute;
}

/* Center the main content */
.content {
  display: flex;
  color: #e1e1e1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4.5rem;
  flex-grow: 1; /* Let the content take up the available vertical space */
}

/* Replace h1 and h2 tags with div and p */
.title {
  font-size: 1.5vw; /* Adjust size dynamically based on width */
  font-weight: bold;
}

.subtitle {
  font-size: 2vw; /* Scales based on screen width */
}

button {
  padding: 2vh 5vw; /* Use vh and vw for responsive button size */
  font-size: 1rem;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.play-button {
  background-color: #4CAF50;
  color: white;
  padding: 2vh 5vw; /* Adjust padding based on viewport for better fit */
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.play-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.terms-and-conditions {
  margin-bottom: 1vh;
  font-size: 1.2vw; /* Adjust for different screen sizes */
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  color: black;
  padding: 3vh;
  border-radius: 1rem;
  max-width: 90vw;
  width: 80vw; /* Responsive modal width */
  text-align: left;
}

/* Modal content adjustments */
.modal-content h3 {
  margin-top: 0;
  font-size: 2vw;
}

.modal-content ul {
  list-style-type: none;
  padding-left: 0;
}

.modal-content ul li {
  margin-bottom: 2vh;
  font-size: 1.5vw;
}

.close-button {
  background-color: #f44336;
  color: white;
  padding: 1.5vh 3vw;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

/* Position footer at the bottom */
.footer {
  width: 100%; /* Make footer span the full width */
  font-size: 0.9rem;
  text-align: center;
  padding: 2vh 0;
  background-color: #0d0d0c; /* Grey background */
  border: solid white 0.01rem;
  color: #f1f1f1; /* Subtle white text */
}

.footer a {
  color: #027a23; /* Subtle white for links and emails */
  font-weight: bold;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

@media (orientation: portrait) {
  .footer {
    margin-top: 1rem;
  }
  .terms-and-conditions {
    margin-bottom: 0rem !important;
  }
}