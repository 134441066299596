@media screen and (orientation: portrait) {
    .chess-and-video {
      display: flex;
      flex-direction: column; /* Stack chessboard and videos vertically */
      align-items: center;
      justify-content: center;
      margin-bottom:25vw;
    }
  
    .videos {
      display: flex;
      flex-direction: row; /* Videos side by side */
      justify-content: center;
      align-items: center;
      margin-top: 2vw; /* Adjust as needed */
    }
  
    video {
      width: 45vw; /* Responsive video size for mobile */
      max-width: 45vw;
      margin: 2vw; /* Space between the videos */
    }
  
    /* Chessboard should take up 80% of the mobile screen width */
    .chessboard-container {
      width: 80vw;
      max-width: 80vw; /* Chessboard will be 80% of the screen width */
      margin-top: 5vw; /* Adjust for spacing above the chessboard */
    }

    video::-webkit-media-controls {
      display: none !important;
    }
    
    video::-webkit-media-controls-enclosure {
      display: none !important;
    }
    
    video::-webkit-media-controls-panel {
      display: none !important;
    }
  }